import Layout from "../components/layout";
import Seo from "../components/seo";
import * as React from "react";

const TermsPage = () => (
    <Layout>
        <Seo title="Terms of Service"/>
        <div className="overflow-hidden lg:relative bg-white py-4 sm:py-8 lg:py-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 lg:max-w-5xl lg:px-8">
                <h1>
                    <span className="block text-3xl font-extrabold text-gray-900 sm:text-4xl">EVITAT AUSTRALIA PTY LTD - Terms of Service</span>
                    <span className="block text-lg text-gray-900">Thank you for using the Evitat Platform and services.</span>
                </h1>
                <p className="text-lg text-gray-900 max-w-full">Evitat Australia Pty Ltd (ABN 11 671 786 324) (<span className="font-extrabold">Evitat</span>) makes the
                    Evitat Platform and services available to Suppliers and Customers on the <span className="font-extrabold">Terms</span> set out in this
                    document. Additional Terms applying to our services are shown on the Evitat Platform, such as our
                    Fees, Privacy Policy and payment terms, and any promotions or special offers we make available from
                    time to time.</p>
                <p className="text-lg text-gray-900 max-w-full">You may accept these Terms by submitting an application
                    or order, opening an account or otherwise commencing to use the Evitat Platform.</p>
                <p className="text-lg text-gray-900 max-w-full">Evitat may update and re-issue these Terms on its
                    website at any time. Updated terms take effect from the time of issue unless another date is
                    specified. This version is issued August 2022.</p>
                <p className="text-lg text-gray-900 max-w-full">If you do not accept these Terms or updated Terms, you
                    should not use the Evitat Platform. You may cease using the platform at any time. These Terms will
                    continue to apply to any services falling within them.</p>
                <h2 className="text-gray-900 text-2xl font-semibold">1. Evitat Platform and Services</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>The Evitat Platform offers Customers and Suppliers a suite of services, features, links and
                        materials, including:
                        <ul className="list-disc text-lg text-gray-900">
                            <li>The ability to open an account with Evitat, with associated security and account
                                management features;
                            </li>
                            <li>A forum and functionality for posting and viewing material, links, comments and
                                marketing and promotional content;
                            </li>
                            <li>The ability for Customers and Suppliers to connect and engage with each other; and</li>
                            <li>Access to a direct-to-customer e-commerce solution including payment platform.</li>
                        </ul>
                    </li>
                    <li>Certain Evitat services are provided for free. Other services attract fees as notified on the
                        Evitat Platform.
                    </li>
                    <li>Evitat will endeavour, but is not obliged, to make reports available to Suppliers on topics such
                        as market analysis, website usage, product performance, Customer ratings and Customer revenue.
                        Evitat may charge fees for certain reports and data. Reports may be added, changed or
                        discontinued at any time by notification on the Evitat Platform.
                    </li>
                    <li>Evitat will develop tools and support for Suppliers to undertake market analysis and
                        solutions.
                    </li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">2. Supplier services</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>The Supplier is not supplying any services to Evitat, and has no claim to fees or revenues
                        received by Evitat, from Customers or otherwise.
                    </li>
                    <li>The Supplier agrees that when it supplies goods or services to Customers, it does so subject to
                        complying with the conditions and fees set out in these Terms.
                    </li>
                    <li>Suppliers operate on the Evitat Platform as independent entities and not as employee, agent,
                        joint venturer, or partner of Evitat.
                    </li>
                    <li>A Supplier is responsible for its own terms with Customers for the supply of goods and services.
                        Evitat is not party to those terms and has no control over or responsibility for them, other
                        than to the limited extent provided by this clause.
                    </li>
                    <li>When a Supplier accepts an order or makes an offer to provide goods or services to a Customer,
                        the Supplier is responsible for the provision of the goods and services in accordance with
                        applicable laws and contractual terms.
                    </li>
                    <li>The Supplier's terms with Evitat Customers must not conflict with anything in these Evitat
                        Terms. To the extent of inconsistency the Supplier agrees that the inconsistent terms are of no
                        effect and are over-ridden by these Evitat Terms. Evitat holds the benefit of this subclause
                        both for itself and on behalf of Customers, and may act on the Customer’s behalf to enforce the
                        clause, but is not obliged to do so.
                    </li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">3. Fees and payment</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>By using Evitat services, you agree to pay the applicable fees notified in these Terms and on the Evitat Platform.</li>
                    <li>Evitat may offer a payment method whereby a Customer can pay a Supplier through the Evitat Platform. In that event, Evitat will act as the collection agent for the Supplier, and is authorised by the Supplier to collect and manage payments on its behalf.</li>
                    <li>Where Evitat acts as a collection agent for Supplier, Evitat must pay Supplier revenue received, less any applicable Evitat fees, taxes and other deductions, by the end of the month following the month in which the revenue was received.</li>
                    <li>Evitat may deduct amounts due to Evitat from amounts collected from Evitat Customers, before remitting payments to Suppliers.</li>
                    <li>Where Evitat facilitates the collection and/or remittance of taxes on behalf of Suppliers, the Supplier instructs and authorizes Evitat to collect taxes on its behalf, and/or to remit such taxes to the relevant authority. Evitat may apply additional amounts in respect of taxes to Customer invoices.</li>
                    <li>If amounts are payable or potentially payable by a Customer to a Supplier, but are not received by Evitat or the Supplier, neither Evitat nor the Supplier owes any amount to the other, unless the non-payment is in breach of these Terms.</li>
                    <li>These terms and the Evitat fees apply regardless of the locations of the Supplier, the Customer and the provision of the goods and services.</li>
                    <li>Evitat may notify Customers and Suppliers, via the Evitat Platform or by individual notice, of reasonable additional terms of payment, relating for example to instalment payments, holding and release of deposits or part-payments, and refunds. Such terms take effect as part of these Terms of Service.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">4. Supplier responsibilities</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>The information and materials provided by a Supplier using the Evitat Platform must be accurate, true and validated. The Supplier is responsible for keeping information and content up-to-date and accurate at all times.</li>
                    <li>Evitat relies on Supplier-provided information and materials in creating content, and may copy, use, edit and re-package materials on the Evitat Platform and otherwise, without seeking separate Supplier consent. If Supplier objects to Evitat’s use of its materials, please contact Evitat. If agreement cannot be reached on their use, we will remove materials within a reasonable time.</li>
                    <li>Suppliers are responsible for:
                        <ul className="list-disc text-lg text-gray-900">
                            <li>understanding and complying with applicable laws and contracts with third parties that apply to its good and services.</li>
                            <li>handling and using personal data of Customers and others in compliance with applicable privacy laws and these Terms, including Evitat’s Privacy Policy.</li>
                            <li>Assessing and complying with obligations under applicable laws to report, collect, remit, or include in your price any applicable Goods and Services Tax, VAT or other taxes.</li>
                        </ul>
                    </li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">5. What transactions do these Terms apply to?</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>A person purchasing goods or services from a Supplier is an Evitat Customer, such that these Terms apply to the transaction, where one or more of the following applies:
                        <ul className="list-disc text-lg text-gray-900">
                            <li>The person requests, orders or pays for the goods or services using the Evitat Platform or an ordering or payment system linked to the Evitat Platform (whether or not the person also uses any other system);</li>
                            <li>The person contacts the Supplier or clicks through a Supplier link about the goods or services using the Evitat Platform, at any time prior to completing the transaction;</li>
                            <li>the Evitat Platform identifies the person as a qualified lead and generates contact information or a click-through link relating to a Supplier;</li>
                            <li>The person uses a link on the Evitat Platform to the Supplier’s web domain or ordering platform, at any time prior to completing the transaction.</li>
                        </ul>
                    </li>
                    <li>Evitat and the Supplier must use all reasonable commercial endeavours to ensure that revenue due from Customers is received, including by promptly issuing invoices and securing payment from customers, dealing with issues and complaints professionally and promptly, and following the terms and processes of any payment platform used.</li>
                    <li>You agree that you will not attempt to circumvent Evitat’s entitlement to fees under these Terms. Neither a Customer nor a Supplier may structure fees, payment methods or payment terms with the purpose or effect of avoiding, delaying or reducing the amounts of payments to which these Terms apply. Any fees that would have been received by Evitat but for a breach of this clause are a debt payable to Evitat immediately upon notice.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">6. Excluded obligations</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>Evitat has no liability to an Evitat Customer in relation to any claim the customer may have against an Evitat Supplier.</li>
                    <li>Evitat will endeavour to offer a quality platform and services to you, but Evitat has no obligation to make any parts of the Evitat Platform or its services available in a particular format, to particular Customers or Suppliers, in particular territories or at all. Evitat may at its absolute discretion offer discounts, rebates, incentives, finance and other commercial offerings to some Suppliers or Customers, or in respect of some Evitat services, but not others.</li>
                    <li>If either Evitat or a Supplier raises a dispute and the parties cannot agree on any matter relating to fees, either party may escalate the dispute to a panel of one senior executive from each party. If the panel cannot resolve the dispute within 30 days, either party may refer the dispute to a reputable commercial arbitrator agreed by the parties, whose decision shall be binding on the parties absent manifest error.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">7. Representations and warranties</h2>
                <p className="text-lg text-gray-900 max-w-full">Each of you and Evitat, as the parties to these Terms, represents and warrants that:</p>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>If a party is an incorporated body, it is validly existing under the laws of its place of incorporation and has the power and authority to carry on its business;</li>
                    <li>it has the power and authority to enter into and perform its obligations under these Terms;</li>
                    <li>entering into and performing its obligations under these Terms will not breach any contractual obligations it owes to any other person;</li>
                    <li>it is accountable for actions performed on its behalf by Personnel;</li>
                    <li>it will comply with all laws, standards and codes of conduct that are related in any way to the performance of these Terms;</li>
                    <li>all representations, materials or information it provides to the other party in connection with these Terms are true and correct; and</li>
                    <li>it will not infringe the Intellectual Property Rights of any person in performing the services or otherwise performing its obligations under these Terms.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">8. Intellectual property rights</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>You acknowledge that all rights, title and interest in the Evitat Platform and any Intellectual Property Rights created, generated or arising in connection with these Terms or the Evitat Platform shall vest in Evitat.</li>
                    <li>Evitat collects data and materials in the course of managing the Evitat Platform and providing our services. The data belongs to Evitat. We may use the data for commercial and marketing purposes, subject always to compliance with applicable law and our Privacy Policy. For example, we may use information about a Customer’s previous purchases in the course of providing our services, but we will never disclose such information unless it is aggregated and/or anonymised.</li>
                    <li>The Supplier retains ownership over any material that it provides to Evitat or Customers using the Evitat Platform. Supplier grants a royalty free, perpetual, global licence to Evitat to use, edit, adapt, modify, copy and otherwise deal with such material, as Evitat deems necessary or convenient for any purposes relating to the Evitat Platform.</li>
                    <li>Suppliers and Customers may use the Intellectual Property Rights inhering in the Evitat Platform solely to the extent necessary to use the Evitat Platform, and for no other purpose.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">9. Indemnity and liability</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>
                        The Supplier indemnifies Evitat and its Personnel against all losses, damages, liabilities, suits, claims and expenses (including legal costs) incurred by the indemnified parties, arising from or in connection with:
                        <ul className="list-disc text-lg text-gray-900">
                            <li>any breach of these Terms by the Supplier;</li>
                            <li>any wilful, unlawful or negligent act or omission of the Supplier or its Personnel in connection with these Terms;</li>
                            <li>any claim by a Customer arising in connection with the Supplier’s conduct or supply of goods or services; or</li>
                            <li>any claim by a third party relating to the infringement by the Supplier of Intellectual Property rights.</li>
                        </ul>
                    </li>
                    <li>Except in the case of wilful default, fraud or dishonesty, neither party will be liable to the other party under or in connection with these Terms for any consequential or indirect loss, loss of savings, loss of data, loss of profits or loss of goodwill even if foreseeable.</li>
                    <li>Nothing in these Terms operates to limit or exclude liability that cannot be limited or excluded by law. Where a law permits Evitat to limit its liability to resupplying services, or refunding the claimant for services, Evitat hereby limits its liability accordingly.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">10. Limiting and terminating accounts</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>Evitat may at any time suspend, limit, impose conditions on or terminate your account or other access to the Evitat Platform for reasons including:
                        <ul className="list-disc text-lg text-gray-900">
                            <li>Breach or suspected breach of these Terms or an Evitat policy (noting that Evitat may give you an opportunity to remedy a breach that is capable of remedy);</li>
                            <li>Cyber-security or system integrity, performance or capacity;</li>
                            <li>Any events or circumstances indicating that you are or may be insolvent;</li>
                            <li>Any actions (whether or not relating to the Evitat Platform) that in Evitat’s opinion are detrimental to the brand, image, goodwill or reputation of the Evitat Platform, or other Customers or Suppliers; and</li>
                            <li>As a result of a dispute raised by any party, or pending the resolution of a dispute process.</li>
                        </ul>

                    </li>
                    <li>Ceasing to use the Evitat Platform, or termination by either party of your account or these Terms will not affect any accrued rights or remedies of either party.</li>
                    <li>Any provision of these Terms that by its nature survives the termination or expiry of these Terms or is expressed to do so will continue in full force and effect.</li>
                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">11. General</h2>
                <ul className="list-[lower-alpha] text-lg text-gray-900">
                    <li>These Terms are governed by the laws in effect in Victoria, Australia, which shall be the jurisdiction for any litigation arising, without regard to conflict of laws principles. The Evitat Platform is a global platform and may be subject to other local, state, national, and international laws.</li>
                    <li>If any clause or part of any clause in these Terms is held by a court to be invalid or unenforceable, that part is to be regarded as having been deleted from these Terms, and these Terms otherwise remain in full force and effect.</li>
                    <li>A right created by these Terms may be waived expressly in writing, but not otherwise. The failure to exercise a right or any delay in exercising a right or remedy provided by these Terms or by Law does not waive the right or remedy. A waiver of a breach of these Terms does not waive any other breach.</li>

                </ul>
                <h2 className="text-gray-900 text-2xl font-semibold">12. Definitions</h2>
                <p className="text-lg text-gray-900 max-w-full"><span className="font-extrabold">Intellectual Property Rights</span> means all past, present, and future rights which may exist or be created under the laws of any jurisdiction in the world, relating to works of authorship, including copyright, moral rights, trademarks, trade secrets, patents and designs, and all applications, registrations, renewals or combinations of any of them.</p>
                <p className="text-lg text-gray-900 max-w-full"><span className="font-extrabold">Customer</span> means any individual or business using the Evitat Platform, in their capacity as an actual or prospective purchaser of goods or services from Evitat or a Supplier.</p>
                <p className="text-lg text-gray-900 max-w-full"><span className="font-extrabold">Evitat Platform</span> means any and all parts of the Evitat website, mobile app, services platform, operating systems, application protocol interfaces, links and supporting software made available from time to time by Evitat to Customers and Suppliers.</p>
                <p className="text-lg text-gray-900 max-w-full"><span className="font-extrabold">Personnel</span> means officers, employees, agents, representatives, consultants and contractors.</p>
                <p className="text-lg text-gray-900 max-w-full"><span className="font-extrabold">Supplier</span> means any individual or business using the Evitat Platform, in their capacity as an actual or prospective supplier of goods or services to Customers.</p>


            </div>
        </div>
    </Layout>
)

export default TermsPage
